.App .navbar-brand {
  font-weight: bold;
}

.Body {
  background-color: red;
}

.Icon {
  max-height: 5em;
  cursor: pointer;
}