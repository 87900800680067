body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%
}
.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}
.Walks {
    text-align: center;
    margin-bottom: 40px;
}

.Walk {
    margin-top: 40px;
}

.Walks h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Walks p {
    color: #999;
}

.WalkImage {
    max-height: 25em
}

.Info {
    border-right: 1px solid rgba(0,0,0,.125);
    padding-right: 0.5em;
    padding-left: 0.5em;
}

.InfoLeft {
    border-right: 1px solid rgba(0,0,0,.125);
    padding-right: 0.5em;
}

.InfoRight {
    padding-left: 0.5em;
}

.InfoImage {
    opacity: 0.5;
}

.WalkFooter {
    padding: .75rem 0rem;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
}
.App .navbar-brand {
  font-weight: bold;
}

.Body {
  background-color: red;
}

.Icon {
  max-height: 5em;
  cursor: pointer;
}
